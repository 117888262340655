<template>
	<div class="createParks">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item label="字典名称：">
					<el-input v-model.trim="formInline.name" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item label="字典编码：">
					<el-input v-model.trim="formInline.code" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<div>
					<el-button type="primary" @click="modifyParkInfo($event, false)" icon="el-icon-plus">新增</el-button>
				</div>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:operation-column-width="240"
				:is-show-selection="false"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
				:isShowIndex="false"
			>
				<!-- 插槽操作栏固定属性operation -->
				<template #operation="{ scope }">
					<el-button @click="showItem(scope.row)" size="mini" type="primary"> 管理字典项 </el-button>
					<el-button @click="modifyParkInfo($event, false, scope.row)" size="mini" type="warning"> 修改 </el-button>
					<el-button @click="onDelete(scope.row)" size="mini" type="danger"> 删除 </el-button>
				</template>
			</Table>
		</div>
		<CreateDictionary ref="createDictionary" @getList="getList" :isShowDetail="isShowDetail" openType="createDictionary" />
		<OperationDicItem ref="operationDicItem"></OperationDicItem>
	</div>
</template>

<script>
export default {
	name: 'dictionaryList',
	components: {
		Table: () => import('@/components/Table/table'),
		CreateDictionary: () => import('./newDialog.vue'),
		OperationDicItem: () => import('./dictionaryItem.vue'),
	},
	data() {
		return {
			formInline: {
				name: '',
				code: '',
				subject: '',
			},
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '字典名称 ',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '字典编码',
					prop: 'code',
					formatter: (row) => {
						return row.code || '-';
					},
				},

				{
					label: '字典描述',
					prop: 'description',
					formatter: (row) => {
						return row.description || '-';
					},
				},
			],
			isShowDetail: false,
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getList();
	},
	methods: {
		// 查询条件
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		//获取列表
		getList() {
			this.loading = true;
			let data = {
				...this.formInline,
				...this.queryData,
			};
			this.$http
				.get(this.api.getDictionaries + '.json', { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						this.tableData = res.data.collection || [];
						this.total = res.data.pagination.totalItems || 0;
						this.loading = false;
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		// 新增/修改/查看公园信息
		modifyParkInfo(event, isShowDetail, parkInfo) {
			this.$refs.createDictionary.init(parkInfo);
			this.isShowDetail = isShowDetail;
		},
		showItem(row) {
			this.$refs.operationDicItem.getTableData(row);
			this.$refs.operationDicItem.dictionItemVisible = true;
		},
		// 删除
		onDelete(row) {
			if (!row.id) return false;
			this.$confirm('此操作将删除数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.$http
						.delete(this.api.getDictionaries + `/${row.id}.json`)
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message({
									type: 'success',
									message: '删除成功!',
								});
								this.getList();
							}
						})
						.catch((e) => {});
				})
				.catch(() => {});
		},
	},
};
</script>

<style lang="scss" scoped>
.el-form {
	.el-form-item {
		margin-bottom: 16px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
</style>
